@import '../Vars.scss';

.appBar {
  padding: 1.5rem 0;
  box-shadow: none;
  background: $secondary-main;
  display: flex;
  margin-top: auto;
  border-bottom: 1rem solid $secondary-dark;
  transform: translate3d(0, 0, 0);
}

.toolBar {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  justify-content: space-between;
  min-height: 42px;
  @media (min-width: 960px) {
    flex-direction: row;
  }
}

.link {
  color: #fff;
}

.ftrLeft {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media (min-width: 960px) {
    width: 50%;
    text-align: left;
    align-items: flex-start;
  }
}

.ftrRight {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media (min-width: 960px) {
    flex-direction: row;
    margin-top: -3.5rem;
  }
}

.ftrLegal {
  line-height: 1rem;
  margin-left: 0rem;
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  max-width: 600px;
  height: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 0rem 1rem 1rem;
}
.modalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modalTitle {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalBody {
  flex-grow: 1;
  overflow-y: auto;
}
