html {
  font-size: 0.9rem;
  /* width:100vw;
  overflow-x:hidden; 
  margin-left: calc(100vw - 100%); */
}

body {
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-family: 'Merriweather Sans', sans-serif;
  /* font-family: "Merriweather", serif; */
  /* color:#054d85; */
  background-color: #f5f7fa;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .MuiSelect-select {
  padding-bottom: 4px;
} */
input.Mui-disabled,
.MuiSelect-select.Mui-disabled {
  /* background: rgba(51, 51, 51, .1); */
  background: repeating-linear-gradient(
    -55deg,
    rgba(51, 51, 51, 0.1),
    rgba(51, 51, 51, 0.05) 10px,
    rgba(51, 51, 51, 0.1) 10px,
    rgba(51, 51, 51, 0.05) 20px
  );
}
.MuiSelect-select:focus,
.MuiMenuItem-root:hover,
.MuiMenuItem-root.Mui-selected {
  background-color: rgba(204, 239, 196, 0.3) !important;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(204, 239, 196, 0.4) !important;
}
.MuiListItem-button:hover {
  text-decoration: none;
  background-color: rgba(204, 239, 196, 0.2) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Merriweather', serif;
  /* font-family: "Merriweather Sans", sans-serif; */
  /* color: darkslategray; */
  color: #205680;
  font-weight: 500;
}

h1,
h2,
h3 {
  font-weight: 500;
}

h1 {
  margin-top: 0;
  font-size: 2rem;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 400px) {
  h1 {
    font-size: 1.6rem;
  }
}

.highlight {
  position: relative;
  display: inline-block;
  z-index: 1;
  font-weight: 700;
}
.highlight:after {
  content: '';
  left: -1.25%;
  /* left: -2%; */

  position: absolute;
  width: 102.5%;
  height: 35%;
  /* width: 105.5%;
  height: 35%; */

  top: 50%;
  -webkit-transform: translateY(-36%);
  transform: translateY(-36%);
  background-color: #ccefc4;
  z-index: -2;
}

h1.topTitle {
  margin-top: 10px;
  margin-bottom: 0;
}
p.topCopy {
  margin-top: 0.5rem;
}

p {
  line-height: 1.4rem;
}

.sub-h2 {
  color: #326822;
  font-size: 1.25rem;
  font-weight: 500;
}

.yn-h2 {
  color: #205680;
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-right: 24px;
}

.sm-font {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.45em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
}

.legal {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.7em;
  text-align: center;
  margin: 0;
  padding: 0;
}

.extraInfo {
  font-size: 0.9rem;
}
.extraInfoBtn {
  text-transform: none !important;
}

h3.table_header {
  margin-top: 0px;
}
h3.table_header span {
  font-family: 'Merriweather', sans-serif;
  font-size: 70%;
  color: #4c5257;
  padding: 0px;
  font-weight: 400;
}

h4 {
  margin-bottom: 0px;
  margin-top: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.buttons {
  display: flex;
  /* justify-content: flex-end; */
  justify-content: space-between;
  flex-direction: row-reverse;
  /* margin-top: 1rem; */
  margin-bottom: 1rem;
}
.button {
  margin-top: 24px !important;
  /* margin-left: 8px !important; */
}

.hw-question-enter {
  opacity: 0;
  /* margin-top: -2rem !important; */
  transform: translate3d(0, -1rem, 0);
}
.hw-question-enter-active {
  opacity: 1;
  /* margin-top: 0 !important; */
  transform: translate3d(0, 0, 0);
  transition: all 750ms ease-in-out;
  /* transition: opacity 1000ms ease-in-out, margin-top 1000ms ease-in-out; */
  /* transition: opacity 1000ms ease-in-out; */
  animation-fill-mode: forwards;
}
.hw-question-exit {
  opacity: 1;
  position: fixed;
}
.hw-question-exit-active {
  opacity: 0;
  transition: opacity 0ms ease-in-out;
  animation-fill-mode: forwards;
}

.error-slide-enter {
  opacity: 0;
  /* margin-top: -.5rem; */
  transform: translate3d(0, -0.5rem, 0);
}
.error-slide-enter-active {
  opacity: 1;
  /* margin-top: 0; */
  /* transition: opacity 500ms ease-in-out, margin-top 500ms ease-in-out; */
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in-out;
}
.error-slide-exit {
  opacity: 1;
  position: fixed;
}
.error-slide-exit-active {
  opacity: 0;
  transition: opacity 0ms ease-in-out;
  /* animation-fill-mode: forwards */
}

input {
  font-size: 16px !important;
}

.autocomplete-dropdown-container {
  position: absolute;
  /* width: auto; */
  font-size: 0.9rem;
  border-radius: 5px;
  /* padding-bottom: 14px;
  margin-bottom: 6px; */
  width: 100%;
  border: 0px solid grey;
  background-color: #fff;
  z-index: 9999;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px #ccc;

  /* background-size: auto 12px;
  background-position: bottom right .5rem;
  background-repeat: no-repeat;
  background-image:
    url('https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png'); */
  cursor: pointer;
}
.wLogo {
  position: static;
  box-shadow: none;
  padding-bottom: 14px;
  /* margin-bottom: 24px; */
  background-size: auto 12px;
  background-position: bottom right 0.5rem;
  background-repeat: no-repeat;
  background-image: url('https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png');
}
.suggestion-item,
.suggestion-item--active {
  text-align: left !important;
  padding: 0 0 0 1rem;
  margin: 6px 0px;
  font-family: Merriweather Sans, 'Helvetica Neue', Arial, sans-serif;
}
.suggestion-item--active {
  /* background-color: rgba(0, 0, 0, 0.04) !important; */
  background-color: #ccefc4;
}

.articles {
  margin: 1rem 0.5rem;
  padding: 1.2rem 1.2rem 0rem 1.2rem;
  text-align: left;
  border-radius: 15px !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: 200ms;
}
.articles:hover {
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.3);
}

#dots #dot1 {
  animation: load 1s infinite;
}

#dots #dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#dots #dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.uploadCopyCta {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #007bff;
}
.fixDropZone {
  position: fixed;
  top: 20;
  width: 100%;
  z-index: 100;
}

.warning {
  margin: 0.5rem auto !important;
  text-align: left;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15) !important;
}

.hw-section-enter {
  opacity: 0;
  transform: translate3d(0, -1rem, 0);
}
.hw-section-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in-out;
  animation-fill-mode: forwards;
}
.hw-section-exit {
  opacity: 1;
  position: fixed;
  transform: translate3d(0, 0, 0);
}
.hw-section-exit-active {
  opacity: 0;
  transform: translate3d(0, -1rem, 0);
  transition: all 500ms ease-in-out;
  animation-fill-mode: forwards;
}

.hw-loading-enter {
  opacity: 0;
  transform: translate3d(0, 1rem, 0);
}
.hw-loading-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in-out;
  animation-fill-mode: forwards;
}
.hw-loading-exit {
  opacity: 1;
  position: fixed;
  transform: translate3d(0, 0, 0) scale(1);
}
.hw-loading-exit-active {
  opacity: 0;
  transform: translate3d(0, -8rem, 0);
  transition: all 500ms ease-in-out;
  animation-fill-mode: forwards;
}

.hw-congrats-enter {
  opacity: 0;
  transform: translate3d(0, 1rem, 0);
}
.hw-congrats-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in-out;
  animation-fill-mode: forwards;
}
.hw-congrats-exit {
  opacity: 1;
  position: fixed;
  transform: translate3d(0, 0, 0);
}
.hw-congrats-exit-active {
  opacity: 0;
  transform: translate3d(0, -1rem, 0);
  transition: all 500ms ease-in-out;
  animation-fill-mode: forwards;
}

.hw-status-enter {
  opacity: 0;
  transform: translate3d(0, -1rem, 0);
  transition: all 500ms ease-in-out;
}
.hw-status-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in-out;
  transition-delay: 500ms;
  animation-fill-mode: forwards;
}
.hw-status-exit {
  opacity: 1;
  position: fixed;
  transform: translate3d(0, 0, 0);
}
.hw-status-exit-active {
  opacity: 0;
  transform: translate3d(0, -1rem, 0);
  transition: all 500ms ease-in-out;
  animation-fill-mode: forwards;
}

.ribbon {
  margin: 0;
  padding: 0;
  background: #ccefc4;
  color: #007bff;
  padding: 0.5em 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top: 0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: #ccefc4;
}
.ribbon:before {
  right: 100%;
}

.ribbon:after {
  left: 100%;
}

.ribbon-paragraph {
  position: absolute;
  padding: 4px;
  background-color: #ffc000; /* Set your desired background color */
  color: #000; /* Set your desired text color */
  margin: 0 -10px;
  z-index: 1; /* Ensure the ribbon content is above the triangles */
  top: 264px;
  width: 99%;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 0.9rem;
}

.ribbon-paragraph::before,
.ribbon-paragraph::after {
  content: '';
  position: absolute;
  /* top: 0; */
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 2; /* Ensure the triangles are behind the ribbon content */
}

.ribbon-paragraph::before {
  left: 0px;
  top: -10px;
  border-width: 10px 10px 0px 0px;
  border-color: transparent #8b4000 transparent transparent;
}

.ribbon-paragraph::after {
  content: '';
  position: absolute;
  bottom: -10px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 0px 10px 10px;
  border-color: transparent transparent transparent #8b4000;
}

.ribbon-paragraph-sm {
  position: absolute;
  padding: 10px 4px;
  background-color: #ffc000; /* Set your desired background color */
  color: #000; /* Set your desired text color */
  margin: 0 auto;
  z-index: 1; /* Ensure the ribbon content is above the triangles */
  bottom: 14px;
  width: calc(100% - 23px);
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 0.9rem;
  border-radius: 0 0 15px 15px;
}

.userEngageSecondary {
  font-size: 0.8rem;
}

@media (max-width: 1000px) {
  .userEngagePrimary {
    display: block;
    text-align: center;
  }
  .userEngageSecondary {
    display: block;
    margin-top: 1rem;
    text-align: center;
  }
}
@media (max-width: 959px) {
  .ribbon-paragraph {
    top: 212px;
  }
}

.offerLegal table {
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
  text-align: center;
}
.offerLegal table td {
  padding: 0.5rem;
  border: 1px solid #ccc;
}
.offerLegal table td span {
  font-weight: bold;
}

pre {
  display: block;
  font: normal 9px/22px Monaco, Monospace !important;
  color: #000000;
  background-color: #ffffff;
  background-image: -webkit-repeating-linear-gradient(top, #ffffff 0px, #ffffff 22px, rgba(204, 239, 196, 0.2) 22px, rgba(204, 239, 196, 0.5) 44px);
  background-image: -moz-repeating-linear-gradient(top, #ffffff 0px, #ffffff 22px, rgba(204, 239, 196, 0.2) 22px, rgba(204, 239, 196, 0.5) 44px);
  background-image: -ms-repeating-linear-gradient(top, #ffffff 0px, #ffffff 22px, rgba(204, 239, 196, 0.2) 22px, rgba(204, 239, 196, 0.5) 44px);
  background-image: -o-repeating-linear-gradient(top, #ffffff 0px, #ffffff 22px, rgba(204, 239, 196, 0.2) 22px, rgba(204, 239, 196, 0.5) 44px);
  background-image: repeating-linear-gradient(top, #ffffff 0px, #ffffff 22px, rgba(204, 239, 196, 0.2) 22px, rgba(204, 239, 196, 0.5) 44px);
  padding: 0em 1em;
  overflow: auto;
}

.error-color {
  color: #dc3545;
}
