@import '../Vars.scss';

.linkGroup {
  text-align: center;
  margin-top: 10px;
  @media (min-width: 600px) {
    text-align: left;
  }
}

.link {
  color: #147bc9;
  text-decoration: none;
  font-weight: 400;
}

.lockup {
  text-align: center;
  font-size: 0.7em;
  color: #054d85;
  vertical-align: text-bottom;
  @media (min-width: 600px) {
    text-align: right;
  }
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  max-width: 600px;
  height: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 0rem 1rem 1rem;
}
.modalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modalTitle {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalBody {
  flex-grow: 1;
  overflow-y: auto;
}
