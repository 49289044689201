@import 'Vars.scss';

.margin {
  margin: 10px;
}

.embed {
  font-size: 0.9rem;
  padding: 2rem 0px;
}

.hero {
  font-size: 0.9rem;
  margin-top: auto;
  padding: 2rem 0px;
}

.centerImg {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.bigLink {
  &:hover {
    text-decoration: none;
  }
}

.icon {
  transition: color 0.125s ease-in;
  padding: 1rem;
  color: slategray;
  font-size: 2rem;
  &:hover {
    color: #17877e;
  }
  @media (max-width: 600px) {
    padding: 0 1rem 0 0;
  }
}

.desktopLogo {
  visibility: hidden;
  @media (min-width: 600px) {
    visibility: visible;
  }
}

.buttonGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 600px) {
    flex-direction: column;
    padding-bottom: 2rem;
  }
}

.btnOption {
  margin: 2rem 3rem 0 0;
  padding: 1rem 3rem;
  border: 1px solid $primary-main;
  color: #fff;
  background: $primary-main;
  font-family: Merriweather;
  text-transform: none;
  font-weight: 900;
  border-radius: 10px;
  box-shadow: rgba(20, 123, 201, 0.4) 0px 8px 25px 0px;
  font-size: 1.125rem;
  @media (max-width: 600px) {
    margin: 2rem auto;
    display: flex;
  }
  &:hover {
    box-shadow: rgba(20, 123, 201, 0.4) 0px 8px 25px 0px;
  }
}

.modelHome {
  max-width: 100%;
  display: block;
  margin: 0 0 auto;
  @media (max-width: 600px) {
    margin: 2rem auto;
  }
}

.businessName {
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 700;
}

.points {
  margin: 2px 0px 0px;
  font-size: 0.8rem;
}
