.appBar {
  padding: 0px;
  box-shadow: none;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transform: translate3d(0, 0, 0);
}

.flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
  align-self: center;
  margin-left: 20px;
}

.toolBar {
  width: 40%;
  // display: flex;
  flex-direction: row;
  min-height: 42px;
  font-family: Lato;
  justify-content: flex-end;
}

.toolBar2 {
  display: flex;
  flex-direction: row;
  min-height: 48px;
}

.link {
  text-transform: none;
  font-size: 0.75rem;
  color: #054d85;
}

.businessNameLink {
  display: flex;
  align-self: center;
  text-decoration: none;
  color: #054d85;
}

.button {
  font-size: 0.8rem;
  text-transform: none;
  padding: 2px 20px;
  border-radius: 4px;
  margin-top: -2px;
}
