$headlines: #054d85;
$textAnchor: #147bc9;

$bodyText: #4c5257;
$mainBg: #f5f7fa;
$highlight: #ccefc4;

$text-secondary: #828587;

$primary-light: #007bff;
$primary-main: #147bc9;
$primary-dark: #054d85;
$primary-contrastText: #fff;

$secondary-light: #ccefc4;
$secondary-main: #326822;
$secondary-dark: #264f1a;
$secondary-contrastText: #fff;
